
import { Component, Vue } from 'vue-property-decorator';

import { Store } from 'vuex';
import { ICablesFilter, ICables } from '@/interfaces';
import { readCablesFilter, readCables, readCablesHeaders, readRowsPerPage } from '@/store/main/getters';
import { dispatchUpdateCablesFilter, dispatchGetCablesHeaders, dispatchUpdateRowsPerPage } from '@/store/main/actions';

@Component
export default class SearchCables extends Vue {
    public cables_search: string = '';
    public pagination = {}
    public selectedRows = [];
    public expand = true;
    public header_cols = {};                                                    // Список столбцов для таблицы


    get cables() {                                                              // Получаем из хранилища список товаров
      return readCables(this.$store);
    }
    
    get cables_count() {                                                        // Возвращаем количество найденных товаров
        return this.$store.getters.cables.length
    }
    
    get headers() {                                                             // Возвращаем список, предназначенный для отрисовки верхней строки таблицы
        var _headers = []
        for (var k in this.header_cols){
            _headers.push({text: this.header_cols[k], 'value': k, 'sortable': false, 'align': 'left'})
        }
        return _headers
    }
    
    get headers_xlsx() {                                                            // Возвращаем список, предназначенный для отрисовки верхней строки таблицы в xlsx
        var _headers = []                                                       
        for (var k in this.header_cols){
            _headers.push({'label': this.header_cols[k], 'field': 'props.' + k})    // Так как сами названия столбцов лежат в поле props, то и берем их оттуда
        }
        return _headers
    }

    public async mounted() {
        await dispatchGetCablesHeaders(this.$store);                            // Получаем из базы и сохраняем в хранилище список всех столбцов для таблицы
        await this.search_cables()
        
        this.header_cols = readCablesHeaders(this.$store);                      // Для удобства кладем названия столбцов в переменную, чтобы каждый раз хранилище не дергать
    }
    
    public created() {                                                          // На создание страницы
        const cablesFilter = readCablesFilter(this.$store);                     // Получаем из хранилища cablesFilter

        if (cablesFilter) {                                                     // и заполняем им форму
            this.cables_search = cablesFilter.cables_search;
        }
       
        const rowsPerPage = readRowsPerPage(this.$store);                       // Получаем из хранилища сохраненное количество товаров на странице                    
        this.pagination.rowsPerPage = (rowsPerPage) ? rowsPerPage : 10          // Если меняли кол-во товаров, то ставим сохраненное, иначе - 10
    }

    public async updatePagination (pagination) {                                // На изменение кол-ва товаров на странице:
        await dispatchUpdateRowsPerPage(this.$store, pagination.rowsPerPage);   // записываем в хранилище кол-во товаров на странице            
    }

    public async search_cables() {                                              // на кнопку "Искать"
        const cablesFilter: ICablesFilter = {};                                 // получаем шаблон фильтра
        cablesFilter.cables_search = this.cables_search;                        // запихиваем в шаблон данные из формы

        await dispatchUpdateCablesFilter(this.$store, cablesFilter);            // Сохраняем в хранилище данные из формы
    }
}
